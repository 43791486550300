import React from 'react';
import { Box, Button, Checkbox, Chip, Drawer, FormControlLabel, Link, Modal, Stack, TextField, Typography,  } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { Amount, LifeEvent, Simulation, VariableDate } from '../../model/Simulation';
import { formatVariableAmount, formatVariableDate } from '../../uiUtils';
import AmountTextField from '../AmountTextField';
import VariableDatePicker from '../VariableDatePicker';
import { modalStyles } from '../styles';
import { v4 as uuidv4 } from 'uuid';

interface IInvestorsProps {
    simulation: Simulation | null;
    onSimulationChange: (simulation: Simulation) => void;
}

const Investors: React.FC<IInvestorsProps> = ({ simulation, onSimulationChange }) => {
    const [showLifeEvents, setShowLifeEvents] = React.useState<boolean>(false);
    const [lifeEvent, setLifeEvent] = React.useState<LifeEvent | null>(null);
    const [showEditLifeEvent, setShowEditLifeEvent] = React.useState<boolean>(false);
    const label = simulation===null?"": "(" + simulation?.scenarios[0].lifeEvents.filter(i => i.active).length + " / " + simulation?.scenarios[0].lifeEvents.length + ")";
    
    const generateEventLines = (lifeEvent: LifeEvent[] | undefined) => lifeEvent?.map((lifeEvent, index) => generateEventLine(lifeEvent, index));
    const generateEventLine = (lifeEvent: LifeEvent | undefined, index:number) => {
        const backgroundColor = index % 2 === 0 ? "white" : "whitesmoke";
        const styles = {backgroundColor: backgroundColor, padding: 1, margin: 0};

        if(lifeEvent === undefined) return <></>;

        return <React.Fragment key={index}>
        <Grid size={1} sx={styles}>
            <Checkbox defaultChecked={lifeEvent.active} onChange={handleLifeEventChange(lifeEvent)} size="small" sx={{p:0}} />
        </Grid>
        <Grid size={3} sx={styles}>
            <Typography variant="body1"><Link href="#" onClick={()=>{setLifeEvent(lifeEvent);setShowEditLifeEvent(true);}}>{lifeEvent.name}</Link></Typography>
        </Grid>
        <Grid size={2} sx={styles} textAlign="right">
            <Typography variant="body1">{formatVariableAmount(lifeEvent.amount)}</Typography>
        </Grid>
        <Grid size={2} sx={styles}>
            {lifeEvent.startDate ? formatVariableDate(lifeEvent.startDate, "today") : ''}
        </Grid>
        <Grid size={2} sx={styles}>
            <Typography variant="body1">{lifeEvent.endDate ? formatVariableDate(lifeEvent.endDate, simulation?.endDate) : ''}</Typography>
        </Grid>
        <Grid size={2} sx={styles} alignItems="center">
          <Stack direction={"row"}>
            {lifeEvent.isInterest?<Chip label="Interest" color="success" size='small' sx={{marginLeft:1}} />:<></>}
            {lifeEvent.isWithDrawal?<Chip label="Withdrawal" color="warning" size='small' sx={{marginLeft:1}} />:<></>}
          </Stack>
        </Grid>
        </React.Fragment>;
    }
        
    const handleLifeEventChange = (lifeEvent: any) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const newSimulation = {...simulation} as Simulation;
        const newLifeEvent = newSimulation?.scenarios[0].lifeEvents.find((l: any) => l.id === lifeEvent.id);
        if (newLifeEvent) {
        newLifeEvent.active = event.target.checked;
        onSimulationChange(newSimulation);
        }
    }

    const handleAddLifeEvent = () => {
        if(simulation) {      
          const newLifeEvents = {id: uuidv4(),  name: 'New Life Event', amount: null as Amount | null, startDate: null, endDate: null, isInterest: false, isWithDrawal: false} as LifeEvent;      
          setLifeEvent(newLifeEvents);
          setShowEditLifeEvent(true);
        }
    }
    
    const handleSaveLifeEvent = () => {
        if(lifeEvent) {
        const newSimulation = {...simulation} as Simulation;
        const newLifeEvents = newSimulation.scenarios[0].lifeEvents.map((l: LifeEvent) => {
            if(l.id === lifeEvent.id) {
            return lifeEvent;
            }
            return l;
        });
        if(!newLifeEvents.find((l: LifeEvent) => l.id === lifeEvent.id)) {
            newLifeEvents.push(lifeEvent);
        }
        newSimulation.scenarios[0].lifeEvents = newLifeEvents;
        onSimulationChange(newSimulation);
        setShowEditLifeEvent(false);
        }
    }

    const handleDeleteLifeEvent = () => {
        if(lifeEvent) {
        const newSimulation = {...simulation} as Simulation;
        newSimulation.scenarios[0].lifeEvents = newSimulation.scenarios[0].lifeEvents.filter((l: LifeEvent) => l.id !== lifeEvent.id);
        onSimulationChange(newSimulation);
        setShowEditLifeEvent(false);
        }
    }

    return <>            
        <Drawer open={showLifeEvents} onClose={()=>setShowLifeEvents(false)}>
          <Box position="relative" sx={{ position: 'absolute', top: 0, right: 0, m: 2 }}>
            <Stack direction="row" spacing={2}>
              <Button variant="contained" onClick={handleAddLifeEvent}>Add</Button>
              <Button variant="contained" onClick={() => setShowLifeEvents(false)}>Close</Button>
            </Stack>
          </Box>
          <Typography variant="h5" p={2}>Life Events</Typography>
          <Grid container size={12} columns={12} width="60vw" p={2}>
            <Grid size={1} textAlign="center">Active</Grid>
            <Grid size={3} textAlign="center">Name</Grid>
            <Grid size={2} textAlign="center">Amount</Grid>
            <Grid size={2} textAlign="center">Starts at</Grid>
            <Grid size={2} textAlign="center">Ends at</Grid>
            <Grid size={2} textAlign="center">Tags</Grid>
            {generateEventLines(simulation?.scenarios[0].lifeEvents.filter((l: LifeEvent) => !l.startDate && l.endDate))}
            {generateEventLines(simulation?.scenarios[0].lifeEvents.filter((l: LifeEvent) => l.startDate && l.endDate))}
            {generateEventLines(simulation?.scenarios[0].lifeEvents.filter((l: LifeEvent) => l.startDate && !l.endDate))}
            {generateEventLines(simulation?.scenarios[0].lifeEvents.filter((l: LifeEvent) => !l.startDate && !l.endDate))}
          </Grid>
        </Drawer>
        
        <Modal open={showEditLifeEvent} onClose={()=>setShowEditLifeEvent(false)}>
          <Box sx={modalStyles}>
            <Typography variant="h6" p={2}>Edit Life Event</Typography>
            <Stack direction="column" spacing={2} p={2}>
              <TextField label="Name" value={lifeEvent?.name} onChange={(e:React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>)=>setLifeEvent({...lifeEvent!, name: e.target.value} ) } />
              <Stack direction="row">
                <FormControlLabel label="Is Interest" control={
                  <Checkbox checked={lifeEvent?.isInterest} onChange={(e:React.ChangeEvent<HTMLInputElement>)=>setLifeEvent({...lifeEvent!, isInterest: e.target.checked} ) } />
                }/>
                <FormControlLabel label="Is Withdrawal" control={
                  <Checkbox checked={lifeEvent?.isWithDrawal} onChange={(e:React.ChangeEvent<HTMLInputElement>)=>setLifeEvent({...lifeEvent!, isWithDrawal: e.target.checked} ) } />
                }/>
                <FormControlLabel label="Is Single Event" control={
                  <Checkbox checked={lifeEvent?.isSingleEvent} onChange={(e:React.ChangeEvent<HTMLInputElement>)=>setLifeEvent({...lifeEvent!, isSingleEvent: e.target.checked} ) } />
                }/>
              </Stack>
              <AmountTextField label="Amount" value={lifeEvent?.amount} onChange={a => setLifeEvent({...lifeEvent!, amount: a } ) } rates={simulation?.rates} />
              <VariableDatePicker label="Start Date" variableDate={lifeEvent?.startDate} onVariableDateChange={(date: VariableDate|null) => setLifeEvent({...lifeEvent!, startDate: date} ) } investors={simulation?.investors} />
              <VariableDatePicker label="End Date" variableDate={lifeEvent?.endDate} onVariableDateChange={(date: VariableDate|null) => setLifeEvent({...lifeEvent!, endDate: date} ) } investors={simulation?.investors} />            
            </Stack>
            <Stack direction="row" spacing={2} p={2}>
              <Button variant="contained" onClick={handleDeleteLifeEvent}>Delete</Button>
              <Button variant="contained" onClick={()=>setShowEditLifeEvent(false)}>Cancel</Button>
              <Button variant="contained" onClick={handleSaveLifeEvent}>Save</Button>            
            </Stack>
          </Box>
        </Modal>

    
        <Button variant="outlined" disabled={!simulation} onClick={()=>setShowLifeEvents(true)} >Life Events {label}</Button>
    </>;
};
    
export default Investors;