import React, { useState } from 'react';
import { Modal, Box, Typography, TextField, Stack, Button } from '@mui/material';
import { modalStyles } from '../styles';
import Logo from '../../logo.svg';
import { SimulationSDK } from '../../SimulationSDK';

interface CodeInputProps {
  sdk: SimulationSDK;
    open: boolean;
    onClose: (code:string) => void;
    onNew: () => void;
}

const LoadScenario: React.FC<CodeInputProps> = ({ sdk, open, onClose, onNew }) => {
  const [code, setCode] = useState<string[]>(['', '', '']);

  const localStyles = { ...modalStyles, width: '700px' };

  const handleChange = (value: string, index: number) => {
    const newCode = [...code];
    newCode[index] = value.toUpperCase();
    setCode(newCode);
    
    if (value.length === 3 && index < 2) {
      const nextInput = document.getElementById(`input-${index + 1}`) as HTMLInputElement;
      if (nextInput) nextInput.focus();
    }
  };

  const handleNew = () => {
    setCode(['', '', '']);
    onNew();
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>, index: number) => {    
    if (e.key === 'Backspace' && code[index] === '' && index > 0) {
      const prevInput = document.getElementById(`input-${index - 1}`) as HTMLInputElement;
      if (prevInput) prevInput.focus();
    }
    if (e.key === 'Enter' && code.join('').length === 9) {
      handleClose();
    }
    if (e.code === '-') {
      e.preventDefault();
    }
  };

  const handlePaste = (e: React.ClipboardEvent) => {
    const pastedData = e.clipboardData.getData('Text').toUpperCase();
    const splitCode = pastedData.split('-');

    if (splitCode.length === 3 && splitCode.every(part => part.length === 3)) {
      setCode(splitCode);
      const lastInput = document.getElementById('input-2') as HTMLInputElement;
      if (lastInput) lastInput.focus();
    }
    e.preventDefault();
  };

  const handleLogin = () => {
  }

  const handleClose = () => {
    onClose(code.join('-'));
    setCode(['', '', '']);
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={localStyles}>
        <Stack direction="column" padding={2} justifyContent="center" alignItems="center">
          <img src={Logo} alt="logo" style={{ width: '60%', height: '100px', margin: 'auto' }} />
          <Typography variant="body1" justifyContent="center" alignItems="center" paddingBottom={1}>
            Please enter the 9-digit code.
          </Typography>
          <Box sx={{ display: 'flex', gap: '10px', justifyContent: 'center' }} p={2}>
            {code.map((value, index) => (
              <TextField
                key={index}
                id={`input-${index}`}
                slotProps={{ htmlInput: { maxLength: 3, style: { letterSpacing: '10px', fontFamily: 'monospace', fontSize: '30pt', textTransform: 'uppercase', textAlign: 'center' }} }}
                value={value}
                onChange={(e) => handleChange(e.target.value, index)}
                onKeyDown={(e) => handleKeyDown(e, index)}
                onPaste={handlePaste}
                variant="outlined"
                size="small"
                sx={{ width: '150px' }}
              />
            ))}
            <Button variant="contained" onClick={handleClose} disabled={code.join('').length !== 9}>Load</Button>
            <Button variant="outlined" onClick={() => setCode(['', '', ''])}>Clear</Button>
          </Box>
          <Stack direction="row" gap={2} justifyContent="center" alignItems="center">
            <Button variant='contained' onClick={handleNew}>New Simulation</Button>
            <Button variant='contained' onClick={handleLogin}>Login</Button>
          </Stack>
          <Stack alignItems="center">
            <Typography variant="body2" justifyContent="center" alignItems="center" paddingTop={2}>
              v{sdk.settings?.version} | v{sdk.settings?.apiversion}
            </Typography>
            <Typography variant="body2" justifyContent="center" alignItems="center">
              © 2024 Nathan Finance. All rights reserved.
            </Typography>
          </Stack>
        </Stack>
      </Box>
    </Modal>
  );
};

export default LoadScenario;
