import { Box, Chip, Stack, Tooltip, Typography } from "@mui/material";
import { Amount, VariableDate } from "./model/Simulation";
import { formatDate } from "./utils";

export const formatVariableDate = (date: VariableDate | null, defaultValue: string|undefined) => {    
  if(!date) return defaultValue;
  if(date.date) {
    return <Stack direction="row" alignItems="center">{formatDate(date.date)}</Stack>;
  }
  if(date.investor) {    
    return <Stack sx={{ whiteSpace: 'nowrap' }} direction="row">
        <Box>{date.age} yo</Box>
        <Chip label={date.investor.name} size="small" color="primary" sx={{ marginLeft: 1 }} />
      </Stack>
  }
  return defaultValue;
} 

export const formatVariableAmount = (amount: Amount | null) => {
  if(!amount) return '';
  if(amount.value) {
    return amount.value + "$";
  }
  if(amount.percentage) {
    return amount.percentage + "%";
  }
  if(amount.rate) {
    return  <Tooltip color="success" sx={{marginLeft:1}} title={amount.rate.name}>
              <Typography>{amount.rate.value + "%"}</Typography>
            </Tooltip>;
  }
  return '';
}
