import React, { useEffect } from 'react';
import { Modal, Box, Typography, Checkbox, Button, TextField, Grid2 as Grid, FormControl, InputLabel, Select, MenuItem, SelectChangeEvent } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Simulation } from '../../model/Simulation';
import { modalStyles } from '../styles';

interface ISettingsProps {
    show: boolean;
    simulation: Simulation | null;
    onSimulationChange: (simulation: Simulation) => void;
    onClose: () => void;
}

const Settings: React.FC<ISettingsProps> = ({ show, simulation, onSimulationChange, onClose }) => {
    const [showSettings, setShowSettings] = React.useState<boolean>(false);

    useEffect(() => {
        if(!showSettings) onClose();        
    }, [showSettings, onClose]);

    useEffect(() => {
        if(show) setShowSettings(true);
    }, [show]);


    const handleEndDateChange = (date: Dayjs | null) => {
        if(date) {
            const newSimulation = {...simulation} as Simulation;
            newSimulation.endDate = date.format('YYYY-MM-DD');
            onSimulationChange(newSimulation);
        }
    }

    const handleWithdrawalExtraOfNeedsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (simulation) {
            simulation.isWithdrawalExtraOfNeeds = e.target.checked;
            onSimulationChange({ ...simulation });
        }
    }

    const handleNonTaxableIncomChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (simulation) {
            simulation.isNonTaxableIncomeCalculatedToItsEquivalent = e.target.checked;
            onSimulationChange({ ...simulation });
        }
    }

    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (simulation) {
            simulation.name = e.target.value;
            onSimulationChange({ ...simulation });
        }
    }

    const handleUseAgeInsteadofYearsAsXAxis = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (simulation) {
            simulation.useAgeInsteadofYearsAsXAxis = e.target.checked;
            onSimulationChange({ ...simulation });
        }
    }

    const handleInvestorChange = (e: SelectChangeEvent<string|null>) => {
        if (simulation) {
            const investor = simulation.investors.find(investor => investor.id === e.target.value);
            simulation.ageXAxisInvestor = investor??null;
            onSimulationChange({ ...simulation });
        }
    }

    return <>
    <Modal open={showSettings} onClose={() => setShowSettings(false)}>
        <Box sx={modalStyles}>
            <Grid container spacing={2}>
                <Grid size={12}>
                    <Typography variant="h6">Settings</Typography>
                </Grid>
                <Grid size={12}>
                    <TextField label="Name" value={simulation?.name} onChange={handleNameChange} />
                </Grid>
                <Grid size={12}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker label="End of simulation" defaultValue={dayjs(simulation?.endDate)} views={['month', 'year']} onChange={handleEndDateChange} />
                </LocalizationProvider>
                </Grid>
                <Grid size={12}>
                    <Checkbox checked={simulation?.isWithdrawalExtraOfNeeds} onChange={handleWithdrawalExtraOfNeedsChange} />
                    Withdrawal are extras
                    </Grid>
                <Grid size={12}>
                    <Checkbox checked={simulation?.isNonTaxableIncomeCalculatedToItsEquivalent} onChange={handleNonTaxableIncomChange} />
                    Non taxable income are calculated to its equivalent
                </Grid>
                <Grid>
                    <Checkbox checked={simulation?.useAgeInsteadofYearsAsXAxis} onChange={handleUseAgeInsteadofYearsAsXAxis} />
                    Use Age instead of Years as X axis
                    <FormControl fullWidth>
                        <InputLabel id="investor-label">Investor</InputLabel>
                        <Select
                            labelId="investor-label"
                            value={simulation?.ageXAxisInvestor?.id ?? ''}
                            label="Investor"
                            onChange={handleInvestorChange}
                        >
                            {simulation?.investors?.map(investor => <MenuItem key={investor.id} value={investor.id}>{investor.name}</MenuItem>)}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid size={12}>
                    <Box sx={{ marginTop: 'auto', display: 'flex', justifyContent: 'flex-end' }}>
                        <Button variant="contained" onClick={() => setShowSettings(false)}>Close</Button>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    </Modal>
    <Button variant="outlined" disabled={!simulation} onClick={()=>setShowSettings(true)}>Settings</Button>
    </>;
}

export default Settings;