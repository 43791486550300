import { Investment, Need, VariableDate } from "./model/Simulation";

export function generateUId(): string {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let code = '';
    
    for (let i = 0; i < 9; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        code += characters[randomIndex];
        
        if ((i + 1) % 3 === 0 && i !== 8) {
            code += '-';
        }
    }
    
    return code;
}

export function resolveReferences(obj: any) {
    const references: { [key: string]: any } = {};

    function isIsoDate(value: string): boolean {
        return !isNaN(Date.parse(value));
    }

    function collectReferences(o: any) {
        if (o && typeof o === 'object') {
            if (o.$id) {
                references[o.$id] = o;
            }
            Object.keys(o).forEach(key => {
                collectReferences(o[key]);
            });
        }
    }

    function resolve(o: any):any {
        if (o && typeof o === 'object') {
            if (o.$values && Array.isArray(o.$values)) {
                return o.$values.map((item: any) => resolve(item));
            }
            if (o.$ref) return resolve(references[o.$ref]);
            if (o.$id)  delete o.$id;
            Object.keys(o).forEach(key => {
                const value = o[key];
                if (typeof value === 'string' && isIsoDate(value)) {
                    o[key] = new Date(value);
                } else {
                    o[key] = resolve(value);
                }
            });
        }
        return o;
    }

    collectReferences(obj);
    const resolved = resolve(obj);
    return resolved;
}

export function serializeReferences(obj: any): string {
    const seenObjects = new Map<any, string>();
    let currentId = 1;

    function getId(o: any): string {
        if (!seenObjects.has(o)) {
            seenObjects.set(o, `${currentId}`);
            currentId++;
        }
        return seenObjects.get(o)!;
    }

    function isDate(value: any): boolean {
        return value instanceof Date && !isNaN(value.getTime());
    }

    function serialize(o: any): any {
        if (isDate(o)) {
            return o.toISOString().split('T')[0];
        }
        if (o && typeof o === 'object') {
            if (Array.isArray(o)) {
                if(seenObjects.has(o)){
                    return { $ref: getId(o) };
                }
                const id = getId(o);
                return { $id: id, $values: o.map(item => serialize(item)) };
            }
            if (seenObjects.has(o)) {
                return { $ref: getId(o) };
            }
            const serializedObject: any = { $id: getId(o) };
            Object.keys(o).forEach(key => {
                serializedObject[key] = serialize(o[key]);
            });
            return serializedObject;
        }
        return o;
    }

    return serialize(obj);
}


export const getAge = (birthString: string) => {
    const birth = new Date(birthString);
    const today = new Date();    
    const age = today.getFullYear() - birth.getFullYear() - 
                (today.getMonth() < birth.getMonth() || 
                (today.getMonth() === birth.getMonth() && today.getDate() < birth.getDate()) ? 1 : 0);
    return age;
}

export const formatCurrency = (amount: number | undefined) => {
    if(amount)
        return amount?.toLocaleString('en-US', { style: 'currency', currency: 'USD', });
    return '';
}

export const formatInvestmentAmount = (investment: Investment) => {
    return investment.currentTotal?.toLocaleString('en-US', { style: 'currency', currency: 'USD', }) + " CAD";
  }

export const formatNeedAmount = (need: Need) => {
    if(need.amount?.value)
      return need.amount.value?.toLocaleString('en-US', { style: 'currency', currency: 'USD', });
    if(need.amount?.percentage)
      return need.amount.percentage + "%";
    return '';
  }

export const getVariableDateNumber = (variableDate: VariableDate | null): number => {
    if (variableDate) {
        if (variableDate.investor && variableDate.age) {
            const birthDate = new Date(variableDate.investor.birthDate);
            const futureDate = new Date(birthDate.getFullYear() + variableDate.age, birthDate.getMonth(), birthDate.getDate());
            return futureDate.getTime();
        } else if (variableDate.date) {
            return new Date(variableDate.date).getTime();
        }
    }
    return 0;
}

export const formatDate = (dateString: string|Date) => {
    if(dateString instanceof Date)
        return dateString.toLocaleDateString();
    const date = new Date(dateString);
    return date.toLocaleDateString();
}