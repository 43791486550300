import React, { useState } from 'react';
import { DatePicker, DatePickerProps, PickerValidDate } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Investor, VariableDate } from '../model/Simulation';
import { FormControl, FormControlLabel, InputLabel, MenuItem, Radio, Select, Stack, TextField } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select/SelectInput';

interface VariableDatePickerProps extends DatePickerProps<PickerValidDate, boolean> {
    variableDate: VariableDate | null | undefined;
    onVariableDateChange: (date: VariableDate | null) => void;
    investors: Investor[] | undefined;
}

const VariableDatePicker: React.FC<VariableDatePickerProps> = ({ variableDate, onVariableDateChange, investors, ...rest }) => {
    const initialDate = variableDate?.date ? dayjs(variableDate.date) : null;
    const initialUseState = variableDate?.date?3:(variableDate?.age?2:1);
    const [selectedDate, setSelectedDate] = useState<dayjs.Dayjs | null>(initialDate);
    const [useDate, setUseDate] = useState<number>(initialUseState);

    const handleDateChange = (date: dayjs.Dayjs | null) => {
        setSelectedDate(date);
        const newDate = date ? dayjs(date).format('YYYY-MM-DD') : null;
        onVariableDateChange({...variableDate, date: newDate } as VariableDate);
    };

    const handleOptionChange = (option: number) => {
        setUseDate(option);
        if (option===1) {
            onVariableDateChange(null);
        } else if (option===2) {
            onVariableDateChange({...variableDate, date: null, age: 0 } as VariableDate);
        } else {
            const date = dayjs(selectedDate).toDate();
            onVariableDateChange({...variableDate, date: date, age: null, investor: null } as VariableDate);
        }
    }

    const handleAgeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newAge = parseInt(event.target.value);
        onVariableDateChange({...variableDate, date: null, age: newAge } as VariableDate);
    };

    const handleInvestorChange = (event: SelectChangeEvent<string|null>) => {
        if(!event.target)
        {
            onVariableDateChange({...variableDate, investor: null } as VariableDate);
        }
        else
        {
            const newInvestor = investors?.find(investor => investor.id === event.target.value);
            onVariableDateChange({...variableDate, investor: newInvestor } as VariableDate);
        }
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Stack spacing={2}>
                <Stack direction="row">
                    <FormControlLabel control={<Radio size="small" />} label="No Date" checked={useDate===1} onChange={handleOptionChange.bind(null, 1)} />
                    <FormControlLabel control={<Radio size="small" />} label="Use Age" checked={useDate===2} onChange={handleOptionChange.bind(null, 2)} />
                    <FormControlLabel control={<Radio size="small" />} label="Use Date" checked={useDate===3} onChange={handleOptionChange.bind(null, 3)} />
                </Stack>
                {useDate===2 && 
                <Stack direction="row" spacing={2}>
                    <TextField
                        label="Age"
                        type="number"
                        value={variableDate?.age ?? ''}
                        onChange={handleAgeChange}
                    />
                    <FormControl fullWidth>
                        <InputLabel id="investor-label">Investor</InputLabel>
                        <Select
                            labelId="investor-label"
                            value={variableDate?.investor?.id ?? ''}
                            label="Investor"
                            onChange={handleInvestorChange}
                        >
                            {investors?.map(investor => <MenuItem key={investor.id} value={investor.id}>{investor.name}</MenuItem>)}
                        </Select>
                    </FormControl>
                </Stack>}
                {useDate===3 && <DatePicker                        
                    value={dayjs(selectedDate)}
                    onChange={handleDateChange}            
                    {...rest}
                />}
            </Stack>
        </LocalizationProvider>
    );
};

export default VariableDatePicker;